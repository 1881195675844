export const API_VERSION = "v1.0";
const HOST_URL = `starcinemas-newmycinema.brij.tech`;
const LIVEBASEURL = `https://starcinemas-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const SOCKETURL = BASEURL;
export const IMG_BASEURL = `https://dd3zjslgvteqz.cloudfront.net`;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const STARCINEMAS_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6Ijc1ZDAzZDQwLTJiYTQtNDk5ZC04ZGU0LTY4NTQ4ODIzZDUxNSIsImlhdCI6MTY3NTE0OTU5Nn0.3Y-WNU0cupbZOK1ty4avrXz-pC6iwyYDbU8yCWA7oCY`;
export const TOKEN = STARCINEMAS_TOKEN;
